import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./nav.css";
import logo from "../pages/assets/logot.png";
import { IoMenu } from "react-icons/io5";

function Navbar({ activePage }) {
  const navigate = useNavigate();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
        setIsMobileMenuOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  const getColor = (page) => {
    return activePage === page ? "#3AC1E0" : "#A6A6A6";
  };

  return (
    <div>
      <div className="navv">
        <div className="container w-100">
          <div className="d-flex align-items-center justify-content-between">
            <img src={logo} alt="" style={{ width: "95px" }} />
            {!isMobileView ? (
              <div className="d-flex">
                <p
                  onClick={() => navigate("/")}
                  className=" fw-semibold textn pointer mb-0 ms-5"
                  style={{
                    caretColor: "transparent",
                    color: getColor("beranda"),
                  }}
                >
                  Beranda
                </p>
                <p
                  onClick={() => navigate("/etalase")}
                  className=" fw-semibold textn pointer mb-0 ms-5"
                  style={{
                    caretColor: "transparent",
                    color: getColor("etalase"),
                  }}
                >
                  Etalase
                </p>
              </div>
            ) : (
              <div>
                <button
                  onClick={toggleMobileMenu}
                  className="btn btn-sm btn-primary d-flex align-items-center p-2"
                  style={{ backgroundColor: "#458CB0", border: "none" }}
                >
                  <IoMenu size={20} />
                </button>
              </div>
            )}
          </div>
          {isMobileMenuOpen ? (
            <div className="p-3">
              <p
                onClick={() => navigate("/")}
                className=" fw-semibold textn pointer mb-4 mt-3"
                style={{
                  caretColor: "transparent",
                  color: getColor("beranda"),
                }}
              >
                Beranda
              </p>{" "}
              <p
                onClick={() => navigate("/etalase")}
                className=" fw-semibold textn pointer mb-4"
                style={{
                  caretColor: "transparent",
                  color: getColor("etalase"),
                }}
              >
                Etalase
              </p>{" "}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
