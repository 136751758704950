import React, { useState, useEffect } from "react";
import "./page.css";
import texts1 from "./assets/text-s1.png";
import texts2 from "./assets/textProduct.png";

import btndocs from "./assets/btn-docs.png";
import imgs2 from "./assets/imgs2.png";

import imgs31 from "./assets/imgs3-1.png";
import imgs32 from "./assets/imgs3-2.png";
import imgs33 from "./assets/imgs3-3.png";
import Footers from "../components/Footers,";
import Navbar from "../components/Navbar";

import prod from "./assets/prod.png";
import prodimg from "./assets/prodimg.png";

import bgv from "./assets/bg.mp4";

function Home() {
  const [fade, setFade] = useState(true);
  const assets = [
    texts1,
    texts2,
    btndocs,
    imgs2,
    imgs31,
    imgs32,
    imgs33,
    prod,
    prodimg,
  ];
  useEffect(() => {
    const loadAssets = async () => {
      const images = assets.map((item) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = item;
          img.onload = () => resolve(item);
          img.onerror = reject;
        });
      });

      try {
        await Promise.all(images);
        setTimeout(() => {
          setFade(false); // Start the fade-out transition
        }, 1000);
      } catch (error) {
        console.error("Failed to load images", error);
      }
    };

    loadAssets();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const handleScroll1 = () => {
      const section = document.getElementById("s1");
      const animatedElements = section.querySelectorAll(".animated-bot-top");

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      }, options);

      animatedElements.forEach((element) => {
        observer.observe(element);
      });
    };

    handleScroll1();

    const handleScroll2 = () => {
      const section = document.getElementById("s2");
      const animatedElements = section.querySelectorAll(".animated-right-left");

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      }, options);

      animatedElements.forEach((element) => {
        observer.observe(element);
      });
    };

    handleScroll2();

    const handleScroll3 = () => {
      const section = document.getElementById("s3");
      const animatedElements = section.querySelectorAll(".animated-left-right");

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      }, options);

      animatedElements.forEach((element) => {
        observer.observe(element);
      });
    };

    handleScroll3();
    const handleScroll4 = () => {
      const section = document.getElementById("s4");
      const animatedElements = section.querySelectorAll(".animated-right-left");

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      }, options);

      animatedElements.forEach((element) => {
        observer.observe(element);
      });
    };

    handleScroll4();
  }, []);

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <Navbar activePage={"beranda"} />
      <div className={`loading-spinner ${!fade ? "fade-out" : ""}`}>
        <div className="spinner"></div>
      </div>{" "}
      {/* ----------- */}
      <div className="sec1" id="s1">
        <video playsInline autoPlay muted loop className="bg-video">
          <source src={bgv} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="container ">
          <div className="">
            <img src={texts1} className="text-s1 animated-bot-top" />
          </div>
          <div className="btn-docs-out">
            <img src={btndocs} className="btn-docs " />
            <p className="textbtn">NIB : 2302240070149</p>
            <p className="textbtn2">
              NOMOR AHU-0014409.AH.01.01.TAHUN 2024
            </p>{" "}
          </div>
        </div>
      </div>
      <div className="secp" id="s2">
        <div className="container ">
          <br />
          <div className="row animated-right-left">
            {isMobileView && (
              <div className=" mb-5 col-12 col-sm-12 col-md-12 col-lg-6 animated-right-left">
                <img src={prodimg} className="imgs2" />
              </div>
            )}

            <div className="mb-5 col d-flex flex-column justify-content-center animated-right-left">
              <img src={prod} className="textps2 mx-auto" />
              <br />
              <p className="descsp2">
                <b>PT. Alma Rezeki Utama</b> adalah perusahaan yang bergerak di
                bidang jasa konstruksi, Industri Peralatan, Perlengkapan, dan
                Bagian Kapal. Berdiri sejak 2024 dan berdomisili Medan,
                berkomitmen untuk menyediakan produk unggulan kepada para
                pelanggan di seluruh Indonesia. <br />
                <br />
                <b> Visi :</b> Menyediakan perahu waterski berkualitas tinggi
                kepada para penggemar olahraga air di Indonesia. <br />
                <br />
                <b>Misi : </b> <br />
                1. Menyediakan produk perahu waterski berkualitas tinggi kepada
                pelanggan. <br />
                2. Memberikan layanan pelanggan yang unggul dan terpercaya.{" "}
                <br />
                3. Membangun jaringan distribusi yang luas dan efisien di
                seluruh Indonesia.
                <br />
                4. Berkontribusi pada pengembangan industri olahraga air di
                Indonesia.
              </p>
            </div>
            {!isMobileView && (
              <div className=" mb-5 col-12 col-sm-12 col-md-12 col-lg-6 animated-right-left">
                <img src={prodimg} className="imgs2" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="sec2" id="s3">
        <div className="container ">
          <br />
          <div className="row animated-left-right">
            <div className=" mb-5 col-12 col-sm-12 col-md-6 animated-left-right">
              <img src={imgs2} className="imgs2" />
            </div>
            <div className="mb-5 col d-flex flex-column justify-content-center animated-left-right">
              <img src={texts2} className="texts2 mx-auto" />
              <h5 className="text-center subtit2">TENTANG PRODUK KAMI</h5>
              <br />
              <p className="descs2">
                Jelajahi berbagai macam produk kami, mulai dari Waterski Boat,
                Wakeboard, Wakesurf, hingga aksesoris yang akan menyempurnakan
                petualangan air anda. Semua produk kami dipilih dengan teliti
                untuk memastikan performa dan kenyamanan terbaik.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="sec3" id="s4">
        <div className="container ">
          <br />
          <div className="row justify-content-around">
            <div className="divs3 mb-5 col-12 col-sm-12 col-md-4 animated-right-left">
              <img src={imgs31} className="imgs3" />
              <h5 className="tit3">Material Berkualitas Tinggi</h5>
              <p className="descs3">
                Dibuat dari bahan-bahan premium yang tahan lama dan tahan
                terhadap korosi, perahu ski kami menjamin ketahanan dan performa
                optimal dalam berbagai kondisi air.
              </p>
            </div>
            <div className="divs3 mb-5 col-12 col-sm-12 col-md-4 animated-right-left">
              <img src={imgs32} className="imgs3" />
              <h5 className="tit3">Mudah dalam Perawatan</h5>
              <p className="descs3">
                Desain perahu ski kami memungkinkan perawatan yang mudah dan
                cepat, sehingga anda bisa menghabiskan lebih banyak waktu
                menikmati aktivitas ski air dan lebih sedikit waktu untuk
                petawatan.{" "}
              </p>
            </div>
            <div className="divs3 mb-5 col-12 col-sm-12 col-md-4 animated-right-left">
              <img src={imgs33} className="imgs3" />
              <h5 className="tit3">Mesin Bertenaga dan Efisien</h5>
              <p className="descs3">
                Dilengkapi mesin yang bertenaga namun hemat bahan bakar, perahu
                ski kami menawarkan kecepatan dan respons yang luar biasa tanpa
                mengorbankan efisiensi.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footers activePage={"beranda"} />
    </div>
  );
}

export default Home;
