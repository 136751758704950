import React, { useState, useEffect } from "react";
import "./page.css";
import Footers from "../components/Footers,";
import Navbar from "../components/Navbar";
import img from "../pages/assets/etalase.png";
import { FaWhatsapp } from "react-icons/fa";

import i1 from "./prodAssets/1.png";
import i2 from "./prodAssets/2.png";
import i3 from "./prodAssets/3.png";
import i4 from "./prodAssets/4.png";
import i6 from "./prodAssets/6.png";
import i62 from "./prodAssets/62.png";
import i63 from "./prodAssets/63.png";
import i64 from "./prodAssets/64.png";
import i65 from "./prodAssets/65.png";
import i66 from "./prodAssets/66.png";
import i67 from "./prodAssets/67.png";
import i68 from "./prodAssets/68.png";
import i69 from "./prodAssets/69.png";
import i610 from "./prodAssets/610.png";
import i611 from "./prodAssets/611.png";
import i612 from "./prodAssets/612.png";
import i613 from "./prodAssets/613.png";
import i614 from "./prodAssets/614.png";
import i615 from "./prodAssets/615.png";
import i616 from "./prodAssets/616.png";
import i617 from "./prodAssets/617.png";
import i618 from "./prodAssets/618.png";

import i7 from "./prodAssets/7.png";
import i72 from "./prodAssets/72.png";
import i73 from "./prodAssets/73.png";
import i74 from "./prodAssets/74.png";
import i75 from "./prodAssets/75.png";
import i76 from "./prodAssets/76.png";

import i8 from "./prodAssets/8.png";
import i82 from "./prodAssets/82.png";
import i83 from "./prodAssets/83.png";
import i84 from "./prodAssets/84.png";
import i85 from "./prodAssets/85.png";
import i86 from "./prodAssets/86.png";

import i9 from "./prodAssets/9.png";
import i92 from "./prodAssets/92.png";
import i93 from "./prodAssets/93.png";
import i94 from "./prodAssets/94.png";
import i95 from "./prodAssets/95.png";
import i96 from "./prodAssets/96.png";
import i97 from "./prodAssets/97.png";
import i98 from "./prodAssets/98.png";
import i99 from "./prodAssets/99.png";
import i910 from "./prodAssets/910.png";

import i11 from "./prodAssets/11.png";
import i12 from "./prodAssets/12.png";
import i13 from "./prodAssets/13.png";

function Etalase() {
  const data = [
    {
      img: i1,
      title: "Waterski Boat + Trailer",
      merk: "-",
      tipe: "Nautique 200",
      spek: "PCM ZR4-400HP-6.0L, Torque465ft-lb, Gera Ratio1.23:1, Length 6.65m, Beam 2.41m Fuel Capacity102 L, Max Capacity 658 kg",
      negara: "USA",
    },
    {
      img: i2,
      title: "Waterski Boat + Trailer",
      merk: "-",
      tipe: "Super Air Nautique G21",
      spek: "PCM ZZ6-450HP-6.2L Direct Injection, Torque 595ft-lb, Gear Ratio 2.0:1, Length 7.19m, Beam 2.59m,Fuel Capacity 176 L, Max Capacity 998kg",
      negara: "-",
    },
    {
      img: i3,
      title: "Jumping Ramp",
      merk: "Waterski JumpRamp",
      tipe: "Standart Competition Ramp",
      spek: "lumunium Framework, Length 7m, Width 4.20m,disassembly system",
      negara: "USA",
    },
    {
      img: i4,
      title: "Sialom Course",
      merk: "Instaslalom",
      tipe: "Complete Course",
      spek: "Model #P101-SS - 6 buoy course package with vinylcoated stainless steel mainline",
      negara: "USA",
    },
    {
      img: i6,
      title: "Waterski Competition Gears",
      merk: "HO",
      tipe: "Slalom Ski",
      spek: `HO 2024 Syndicate Works 02 Slalom Ski 66" `,
      negara: "USA",
    },
    {
      img: i62,
      title: "Waterski Competition Gears",
      merk: "D3",
      tipe: "Trick Ski",
      spek: `42"`,
      negara: "USA",
    },
    {
      img: i63,
      title: "Waterski Competition Gears",
      merk: "Goodman",
      tipe: "Jump Ski",
      spek: `86"`,
      negara: "USA",
    },
    {
      img: i64,
      title: "Waterski Competition Gears",
      merk: "Wileys",
      tipe: "Slalom and TrickBinding",
      spek: `Pro Series L size, High wrap front`,
      negara: "USA",
    },
    {
      img: i65,
      title: "Waterski Competition Gears",
      merk: "Wileys",
      tipe: "Slalom rear binding",
      spek: `L size`,
      negara: "USA",
    },
    {
      img: i66,
      title: "Waterski Competition Gears",
      merk: "Wileys",
      tipe: "Trick rear binding",
      spek: `Pro trick plate L Size`,
      negara: "USA",
    },
    {
      img: i67,
      title: "Waterski Competition Gears",
      merk: "Wileys",
      tipe: "Jump Binding",
      spek: `Super Jump plates"`,
      negara: "USA",
    },
    {
      img: i68,
      title: "Waterski Competition Gears",
      merk: "HO",
      tipe: " Ski Binding",
      spek: `Right,size 6-7`,
      negara: "USA",
    },
    {
      img: i69,
      title: "Waterski Competition Gears",
      merk: "Masterline",
      tipe: "Slalom Ropes",
      spek: `Dlx 9.75m "Pro" Mainline Water Ski Rope (9Section)`,
      negara: "USA",
    },
    {
      img: i610,
      title: "Waterski Competition Gears",
      merk: "Masterline",
      tipe: "Slalom Handle",
      spek: `Protect carbon diameter size 1" length 13"`,
      negara: "USA",
    },
    {
      img: i611,
      title: "Waterski Competition Gears",
      merk: "Masterline",
      tipe: "Trick Rope",
      spek: `4 section mainline, length 14.5m, 6" float at end`,
      negara: "USA",
    },
    {
      img: i612,
      title: "Waterski Competition Gears",
      merk: "Masterline",
      tipe: "Jump Rope",
      spek: `12" heavy duty elastic heel strap, 10" floats, Spiralbraid of 5/16" poly-propylene rope`,
      negara: "USA",
    },
    {
      img: i613,
      title: "Waterski Competition Gears",
      merk: "Masterline",
      tipe: "Jump Rope",
      spek: `20/50 jump mainline (70' main, 20' spectra/50 poly-pro)`,
      negara: "USA",
    },
    {
      img: i614,
      title: "Waterski Competition Gears",
      merk: "Stokes",
      tipe: "Arm Sling",
      spek: `Medium Size`,
      negara: "USA",
    },
    {
      img: i615,
      title: "Waterski Competition Gears",
      merk: "Eagle",
      tipe: "Jump Suit",
      spek: `Rubberized back waste band to eliminate belt slippage  during jumps`,
      negara: "USA",
    },
    {
      img: i616,
      title: "Waterski Competition Gears",
      merk: "Icaro",
      tipe: "Jump Helmet",
      spek: `Sky runner`,
      negara: "Italy",
    },
    {
      img: i617,
      title: "Waterski Competition Gears",
      merk: "Stealth",
      tipe: "Gloves",
      spek: `Kevlar gloves M size`,
      negara: "USA",
    },
    {
      img: i618,
      title: "Waterski Competition Gears",
      merk: "Masterline",
      tipe: "Jump Handle",
      spek: `Krueger Ski fly handle`,
      negara: "USA",
    },
    {
      img: i7,
      title: "Wakeboard & Wakesurf Competition Gears",
      merk: "Masterline",
      tipe: "Jump",
      spek: `Krueger`,
      negara: "USA",
    },
    {
      img: i7,
      title: "Wakeboard & Wakesurf Competition Gears",
      merk: "Masterline",
      tipe: "3 event bag",
      spek: `Heavy duty foam padded bag, full length 2 way zipper, heavy  duty urethane wheels `,
      negara: "USA",
    },
    {
      img: i72,
      title: "Wakeboard & Wakesurf Competition Gears",
      merk: "Ronix",
      tipe: "Wakeboard Board &boots Package",
      spek: `Ronix 2022 Supreme Package with 141 Board size and  Medium Boots Size`,
      negara: "USA",
    },
    {
      img: i73,
      title: "Wakeboard & Wakesurf Competition Gears",
      merk: "Hyperlite",
      tipe: "Wakeboard Rope &Handle",
      spek: `Hyperlite 2022 Murray w/floating silicone flat line`,
      negara: "USA",
    },
    {
      img: i74,
      title: "Wakeboard & Wakesurf Competition Gears",
      merk: "Liquid Force",
      tipe: "Competition vest",
      spek: `2022 Women Breeze S size`,
      negara: "USA",
    },
    {
      img: i75,
      title: "Wakeboard & Wakesurf Competition Gears",
      merk: "Liquid Force",
      tipe: "Competition vest",
      spek: `2022 squad no CGA - Black M size`,
      negara: "USA",
    },
    {
      img: i76,
      title: "Wakeboard & Wakesurf Competition Gears",
      merk: "Liquid Force",
      tipe: "Wakesurf Board",
      spek: `2023 TC Skim Board `,
      negara: "USA",
    },

    {
      img: i8,
      title: "Competition Buoys",
      merk: "Hyperlite",
      tipe: "Wakesurf Rope &Handle",
      spek: ` length 25", 10" handle w/ team grip, oversized foam, 4 machined floats inlcuded`,
      negara: "USA",
    },
    {
      img: i82,
      title: "Overtons",
      merk: "Marker Buoys",
      tipe: "Wakesurf",
      spek: `Waterski slalom buoy 9" orange color`,
      negara: "USA",
    },
    {
      img: i83,
      title: "Competition Buoys",
      merk: "Polyfoam",
      tipe: "Marker Buoys",
      spek: `length 25", 10" handle w/ team grip, oversized foam, 4 machined floats inlcuded`,
      negara: "USA",
    },
    {
      img: i84,
      title: "Competition Buoys",
      merk: "Overtons",
      tipe: "Marker Buoys",
      spek: `Waterski slalom Bbuoy 9" green color`,
      negara: "USA",
    },

    {
      img: i85,
      title: "Competition Buoys",
      merk: "Marker Buoys",
      tipe: "Wakesurf",
      spek: `Waterski slalom buoy 9" yellow color`,
      negara: "USA",
    },
    {
      img: i86,
      title: "Competition Buoys",
      merk: "Overtons",
      tipe: "Marker Buoys",
      spek: `Inflatable Guide buoys w/ orang color`,
      negara: "USA",
    },

    {
      img: i9,
      title: "Scoring System",
      merk: "LG",
      tipe: `TV LED 32"`,
      spek: `Dynamic Color, HDMI, Virtual Surround`,
      negara: "USA",
    },
    {
      img: i92,
      title: "Scoring System",
      merk: "-",
      tipe: "Video Capture",
      spek: `-`,
      negara: "-",
    },

    {
      img: i93,
      title: "Scoring System",
      merk: "-",
      tipe: "Tranmitter Video",
      spek: `-`,
      negara: "-",
    },

    {
      img: i94,
      title: "Scoring System",
      merk: "-",
      tipe: "Reciever Video",
      spek: `-`,
      negara: "-",
    },

    {
      img: i95,
      title: "Scoring System",
      merk: "-",
      tipe: "Video Splitter 4 Port",
      spek: `-`,
      negara: "-",
    },

    {
      img: i96,
      title: "Scoring System",
      merk: "-",
      tipe: "Kabel AV",
      spek: `-`,
      negara: "-",
    },

    {
      img: i97,
      title: "Scoring System",
      merk: "-",
      tipe: "PC Dekstop",
      spek: `core i7, RAM 8GB, HDD 500GB, VGA integrated intel HD`,
      negara: "-",
    },

    {
      img: i98,
      title: "Scoring System",
      merk: "-",
      tipe: "CCTV Camera",
      spek: `waterproofed`,
      negara: "Indonesia",
    },

    {
      img: i99,
      title: "Scoring System",
      merk: "JVC",
      tipe: "Handycam",
      spek: `-`,
      negara: "-",
    },

    {
      img: i910,
      title: "Scoring System",
      merk: "Panasonic",
      tipe: "DVD Player",
      spek: `-`,
      negara: "-",
    },

    {
      img: i11,
      title: "Floating Dock ",
      merk: "HDPE",
      tipe: "Kubus Apung ",
      spek: `50cm x 50cm x 25cm, ukuran 5m x 5m (25m2)`,
      negara: "China",
    },
    {
      img: i12,
      title: "Aki boat penarik ",
      merk: "GS",
      tipe: "-",
      spek: `70Ampere`,
      negara: "-",
    },
    {
      img: i13,
      title: "Filter Oli ",
      merk: "-",
      tipe: "-",
      spek: `-`,
      negara: "-",
    },
  ];
  const [fade, setFade] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const loadAssets = async () => {
      const images = data.map((item) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = item.img;
          img.onload = resolve;
          img.onerror = reject;
        });
      });

      try {
        await Promise.all(images);
        setTimeout(() => {
          setFade(false); // Start the fade-out transition
        }, 1000);
      } catch (error) {
        console.error("Failed to load images", error);
      }
    };

    loadAssets();

    const handleScroll1 = () => {
      const section = document.getElementById("se1");
      const animatedElements = section.querySelectorAll(".animated-left-right");

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      }, options);

      animatedElements.forEach((element) => {
        observer.observe(element);
      });
    };

    handleScroll1();
  }, []);

  return (
    <div>
      <Navbar activePage={"etalase"} />
      <div className={`loading-spinner ${!fade ? "fade-out" : ""}`}>
        <div className="spinner"></div>
      </div>
      {/* ----------- */}
      <br />
      <br />
      <br />
      <div className="container" id="se1">
        <img src={img} className="imges1 mx-auto d-flex " />
        <br />
        <div className="row">
          {data.map((item, index) => (
            <div
              key={index}
              className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4 animated-left-right"
            >
              <div className="card-e mb-3 p-3">
                <img src={item.img} className="w-100" />
                <br />
                <div className="d-flex h-100 flex-column justify-content-between">
                  <div>
                    <p className="mb-3 title-e">{item.title}</p>
                    <table>
                      <tr>
                        <td style={{ width: "30%" }}>
                          <p className="desc-e mb-1">Merk</p>
                        </td>
                        <td>
                          <p className="desc-e mb-1">:</p>
                        </td>
                        <td className="w-100">
                          <p className="desc-e ms-2 mb-1">{item.merk}</p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%" }}>
                          <p className="desc-e mb-1">Tipe</p>
                        </td>
                        <td>
                          <p className="desc-e mb-1">:</p>
                        </td>
                        <td className="w-100">
                          <p className="desc-e ms-2 mb-1">{item.tipe}</p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%" }}>
                          <p className="desc-e mb-1">Spesifikasi</p>
                        </td>
                        <td>
                          <p className="desc-e mb-1">:</p>
                        </td>
                        <td className="w-100">
                          <p className="desc-e ms-2 mb-1">{item.spek}</p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%" }}>
                          <p className="desc-e mb-1">Negara</p>
                        </td>
                        <td>
                          <p className="desc-e mb-1">:</p>
                        </td>
                        <td className="w-100">
                          <p className="desc-e ms-2 mb-1">{item.negara}</p>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <button className="btn-order mt-auto d-flex">
                    <FaWhatsapp className="me-2" color="#35FF49" size={20} />{" "}
                    Pesan Melalui Whatsapp
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <br />
      </div>{" "}
      <Footers activePage={"etalase"} />
    </div>
  );
}

export default Etalase;
